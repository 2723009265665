<template>
  <div>
    <ms-select
      v-if="form.depositType"
      v-model="form.account"
      label="Account"
      :options="accountOptions"
      :validator="$v.form.account"
      filterable />

    <template v-if="form.account">
      <template v-if="isFunds">
        <ms-select
          v-model="form.deposit_type"
          :options="depositOptions"
          :validator="$v.form.deposit_type"
          label="Method"
          placeholder="Select Withdrawal Method" />

        <div class="flex">
          <div class="w-2-3">
            <ms-input
              v-model="form.amount"
              label="Amount"
              :validator="$v.form.amount" />
          </div>
        </div>

        <div v-if="invalidBalance" class="error-text"><span>Exceeds available balance. Please lower amount and try again.</span></div>
        <div v-if="invalidToProvider" class="erro-text"><span>Receiver cannot accept Account to Account Transfers. Please check information and try again.</span></div>
      </template>

      <template v-else>
        <ms-select
          v-model="form.sendingTo"
          :options="sendingToOptions"
          :validator="$v.form.sendingTo"
          label="Sending to"
          placeholder="Select Sending to" />

        <div class="flex">
          <ms-select
            v-model="form.assetsCurrency"
            :options="assetsCurrencyOptions"
            :validator="$v.form.assetsCurrency"
            class="w-1-3"
            label="Assets Type"
            placeholder="Select Assets Type" />

          <div class="w-2-3 ml-20">
            <ms-input
              v-model="form.assetsAmount"
              label="Units Withdrawal Amount"
              :validator="$v.form.assetsAmount" />
          </div>
        </div>
      </template>

      <div class="sum">
        <div>Fee: <b>{{ form.depositType == 'Funds' ? $money(fee) : $money(assetsFee) }}</b></div>
        <div>Total withdrawal: <b>{{ form.depositType == 'Funds' ? $money(total) : $assets_balance(totalAssets, 10) }}</b></div>
      </div>

      <!-- DYNACIC FORMAS -->
      <template v-if="showA2AAccountNumber">
        <div class="hr" />
        <ms-select
          v-model="form.a2a_account_number"
          :options="linkedAccountOptions"
          :validator="$v.form.a2a_account_number"
          label="Linked Account"
          placeholder="Select Linked Account"
          is-group />
        <div class="erro-text" v-if="invalidAccountNumber"><span>Invalid Account Number.</span></div>
        <div class="erro-text" v-if="invalidFundsCurrency"><span>{{ form.currency }} not available.</span></div>
        <div class="erro-text" v-if="errorWallet"><span>This account does not have an activated wallet for the asset type chosen. Please check information and try again.</span></div>
      </template>

      <template v-else>
        <template v-if="isFunds">
          <international-wire-form
            v-if="showInternationalWireForm"
            v-model="form"
            :v="$v" />

          <ach-form
            v-if="showACHForm"
            v-model="form"
            :v="$v"
            :hide-bank-account-type="form.deposit_type === 'domestic_wire'" />

          <template v-if="showPayee">
            <div class="hr" />

            <h3 class="subtitle">PAYEE INFORMATION</h3>

            <ms-input
              v-model="form.payee"
              label="Payee"
              :validator="$v.form.payee" />
          </template>

          <template v-if="showAddressForm">
            <div class="hr" />
            <h3 class="subtitle">{{ form.deposit_type === 'domestic_wire' ? 'BENEFICIARY ADDRESS' : 'PAYEE ADDRESS' }}</h3>
            <address-form
              v-model="form"
              :v="$v"
              :us-only="form.deposit_type !== 'domestic_wire'" />
          </template>

          <further-credit-account-form
            v-if="showFurtherCreditAccount"
            v-model="form"
            :v="$v"
          />
        </template>
        <template v-else>
          <div class="hr" />

          <ms-input
            v-model="form.payeeToName"
            label="Pay to Name"
            :validator="$v.form.payeeToName" />

          <ms-input
            v-model="form.payeeToWalletAddress"
            label="Pay to Wallet Address"
            :validator="$v.form.payeeToWalletAddress" />

          <ms-input
            v-model="form.tag"
            label="Destination Tag"
            :validator="$v.form.tag" />
        </template>
      </template>

      <div class="extra-info flex-center">
        <el-button type="text" @click="showExtraInfo = !showExtraInfo" class="link-text-button">
          Extra info <i :class="showExtraInfo ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
        </el-button>
      </div>

      <transition name="el-collapse-transition">
        <div v-show="showExtraInfo">
          <ms-input
            v-model="form.memo"
            label="Memo"
            :maxlength="150" />

          <ms-input
            v-model="form.reference"
            label="Reference" />

          <!-- <div class="flex align-items-center">
            <el-button
              type="info"
              size="small"
              :icon="form.save_as_template ? 'el-icon-check' : 'el-icon-plus'"
              :plain="!form.save_as_template"
              circle
              @click="form.save_as_template = !form.save_as_template" />

            <el-button type="text" @click="form.save_as_template = !form.save_as_template" class="link-text-button">
              Save as a Template
            </el-button>
          </div> -->

          <ms-input
            v-if="form.save_as_template"
            v-model="form.template_name"
            label="Template Name" />
        </div>
      </transition>

      <div class="flex-buttons">
        <el-button
          type="default"
          class="max-w-1-3"
          :disabled="loading.submitting"
          round
          @click="goBack()">
          Cancel
        </el-button>
        <el-button
          class="gradient"
          type="success"
          :loading="loading.submitting"
          round
          @click="submit()">
          Submit
        </el-button>
      </div>

      <ms-modal
        v-if="addConfirmModal"
        :title="`Transaction: ${transaction.transactionId}`"
        :type="transaction.status === 'Error' ? 'error' : 'success'"
        gray
        @close="finish()">
        <template v-if="transaction">
          <data-list :list="transactionInitData" />

          <div class="flex-between mt-10">
            <data-list-item :item="transactionOtherData.amount" />
            <data-list-item :item="transactionOtherData.fee" />
            <data-list-item v-if="transactionOtherData.total" :item="transactionOtherData.total" />
          </div>

          <div class="hr" />

          <data-list
            v-if="transaction"
            class="mt-10"
            :list="transactionData" />
        </template>

        <div class="buttons">
          <el-button @click="finish()" round>
            Close
          </el-button>
        </div>
      </ms-modal>
    </template>
  </div>
</template>

<script>
import { assetsAmount, alphaNum, swift, alphaNumNoSpace } from '../../components/common/validations'
import { required, numeric, minLength, maxLength, maxValue, decimal, minValue } from 'vuelidate/lib/validators'

import AchForm from './forms/ACHForm'
import AddressForm from './forms/AddressForm'
import DataListItem from '@/components/common/ui/DataListItem'
import InternationalWireForm from './forms/InternationalWireForm'
import FurtherCreditAccountForm from './forms/FurtherCreditAccountForm'

export default {
  components: {
    AchForm,
    AddressForm,
    DataListItem,
    InternationalWireForm,
    FurtherCreditAccountForm
  },

  data () {
    return {
      form: {
        account: null,
        currency: 'USD',
        deposit_type: null,
        amount: null,

        bank_name: null,
        account_type: null,
        routing_number: null,
        account_number: null,
        bank_account_type: null,

        a2a_account_number: null,

        payee: null,

        country: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        postal: null,

        bank_country: null,
        bank_address: null,
        bank_city: null,
        bank_state: null,
        bank_postal: null,

        account_name: null,
        swift: null,
        iban: null,
        use_intermediary_bank: true,
        intermediary_bank_name: null,
        intermediary_bank_reference: null,
        intermediary_bank_routing_number: null,
        intermediaryBankIdentifier: null,
        intermediary_bank_swift_code: null,
        intermediary_address: {
          country: null,
          address: null,
          city: null,
          state: null,
          postal: null,
          disabled: true
        },

        use_further_credit_account: false,
        futher_credit_acc_name: null,
        futher_credit_acc_number: null,

        reference: null,
        memo: null,

        save_as_template: false,
        template_name: null,
        depositType: 'Funds',
        assetsCurrency: null,
        assetsAmount: null,
        payeeToWalletAddress: null,
        tag: null,
        payeeToName: null,
        sendingTo: null,
        contributionIncomingWalletAddress: null
      },

      showExtraInfo: false,

      transaction: null,
      addConfirmModal: false,

      fee: null,
      legacyFee: null,
      feePercent: null,
      assetsFee: null,

      sendingToOptions: window.sendingToOptions,
      toRegistrations: null,

      submitError: false,
      invalidAccountNumber: false,
      invalidFundsCurrency: false,
      errorWallet: false,
      invalidBalance: false,
      invalidToProvider: false,

      loading: {
        general: false,
        account: false,
        submitting: false
      }
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    showInternationalWireForm () {
      return this.form.deposit_type === 'international_wires'
    },

    showFurtherCreditAccount () {
      return ['domestic_wire', 'international_wires'].includes(this.form.deposit_type)
    },

    showPayee () {
      return this.form.deposit_type === 'checks'
    },

    showAddressForm () {
      return ['domestic_wire', 'checks'].includes(this.form.deposit_type)
    },

    showA2AAccountNumber () {
      return (!this.isFunds && this.form.sendingTo === 'AccountTransfer') || (this.isFunds && this.form.deposit_type === 'acc_to_acc')
    },

    showACHForm () {
      return ['ach', 'domestic_wire'].includes(this.form.deposit_type)
    },

    isFunds () {
      return this.form.depositType === 'Funds'
    },

    registration () {
      return this.user?.registration || null
    },

    accounts () {
      return this.registration?.accounts || []
    },

    accountOptions () {
      let accounts = []

      this.accounts.forEach(account => {
        if (this.form.depositType === 'Funds' || (account.profileSettings && account.profileSettings.assets)) {
          accounts.push({
            id: account.id,
            regId: this.registration.id,
            status: account.accountStatus,
            text: `${account.accountNumber}-${this.registration.name}`,
            accountNumber: account.accountNumber,
            provider: account.provider,
            profileSettings: account.profileSettings,
            fundsCurrencies: account.fundsCurrencies,
            name: account.accountName,
            assetsWallets: account.assetsWallets,
            systemAvailableBalance: account.systemAvailableBalance,
            disabled: account.accountStatus === 'Restricted' || account.accountStatus === 'Hold' || account.accountStatus === 'Dormant'
          })
        }
      })

      accounts = accounts.filter(account => ['Open'].includes(account.status))

      return accounts
    },

    currencyOptions () {
      const options = [{
        id: 'USD',
        text: 'USD'
      }]

      this.$store.state.app.currencies.forEach(currency => {
        if (currency.systemCurrencyType === 'Funds' && this.selectedAccount?.fundsCurrencies) {
          const fundCurrency = this.selectedAccount.fundsCurrencies.find(item => item.currencyId === currency.id)
          if (fundCurrency?.currencyEnabled) {
            options.push({
              id: currency.currencyNickname,
              text: currency.currencyNickname
            })
          }
        }
      })

      return options
    },

    assetsCurrencyOptions () {
      const options = []
      this.$store.state.app.currencies.forEach(currency => {
        if (currency.tradeDesk && this.selectedAccount?.assetsWallets) {
          const wallets = this.selectedAccount.assetsWallets.filter(wallet => wallet.currencyId === currency.id)
          if (wallets[0]?.incomingAssetWalletAddress && currency.systemCurrencyType === 'Assets') {
            options.push({
              id: currency.id,
              text: currency.currencyNickname
            })
          }
        }
      })

      return options
    },

    linkedAccountOptions () {
      const accounts = []
      if (this.linkedAccounts) {
        this.linkedAccounts.forEach(account => {
          if (this.selectedAccount.provider === account.provider) {
            accounts.push({
              id: account.accountNumber,
              text: account.text
            })
          }
        })
      }

      let corpAccounts = []
      if (this.toRegistrations?.length > 0) {
        const toAccounts = this.toRegistrations[0].accounts
        if (toAccounts) {
          corpAccounts = toAccounts?.filter(acc => (acc.linked === true || acc.solidLinked === true) && acc.provider === this.selectedAccount?.provider).map(acc => {
            const accNumber = acc?.accountNumber ? ' ****-' + acc?.accountNumber.substr(-3) + '-' + acc?.accountName : null
            return {
              id: acc?.accountNumber,
              text: accNumber
            }
          })
        }
      }
      return [
        {
          label: '',
          options: this.$uniqueBy(accounts, 'id')
        },
        {
          label: 'Corporate Accounts',
          options: this.$uniqueBy(corpAccounts, 'id')
        }
      ]
    },

    selectedAccount () {
      return this.accountOptions.find(item => item.id === this.form.account)
    },

    currentAccount () {
      if (!this.selectedAccount?.accounts) return null
      return this.selectedAccount.accounts.find(item => item.id === this.form.account)
    },

    total () {
      return (parseFloat(this.form.amount) + parseFloat(this.fee)) || 0
    },

    totalAssets () {
      return (parseFloat(this.form.assetsAmount)) || 0
    },

    systemFeatures () {
      return this.$store.state.app.systemFeatures
    },

    fundsFeature () {
      return this.systemFeatures.Funds
    },

    assetsFeature () {
      return this.systemFeatures.Assets
    },

    featureFundsStatus () {
      return this.systemFeatures['Multi-Currency']
    },

    typeOptions () {
      const options = []

      if (this.fundsFeature) {
        options.push({
          id: 'Funds',
          text: 'Funds'
        })
      }

      if (this.assetsFeature) {
        options.push({
          id: 'Assets',
          text: 'Assets'
        })
      }

      return options
    },

    depositOptions () {
      const options = [
        {
          id: 'ach',
          text: 'ACH',
          if: this.selectedAccount?.profileSettings?.ach
        },
        {
          id: 'checks',
          text: 'Check',
          if: this.selectedAccount?.profileSettings?.checks
        },
        {
          id: 'domestic_wire',
          text: 'Domestic Wire',
          if: this.selectedAccount?.profileSettings?.domWires
        },
        {
          id: 'international_wires',
          text: 'International Wire',
          if: this.selectedAccount?.profileSettings?.intlWires
        },
        {
          id: 'acc_to_acc',
          text: 'Account Transfer',
          if: this.selectedAccount?.profileSettings?.acc_to_acc
        }
      ]
      return options.filter(opt => opt.if)
    },

    previousPageUrl () {
      return this.$router.options?.history?.state?.back || { name: 'Dashboard' }
    },

    furtherCreditData () {
      return [
        {
          label: 'Further Credit Account Name',
          text: this.form.futher_credit_acc_name
        },
        {
          label: 'Further Credit Account Number',
          text: this.form.futher_credit_acc_number
        }
      ]
    },

    withdrawOptionsText () {
      if (this.form.deposit_type === 'checks') return 'Check'
      if (this.form.deposit_type === 'domestic_wire') return 'Domestic Wire'
      if (this.form.deposit_type === 'international_wires') return 'International Wire'
      if (this.form.deposit_type === 'ach') return 'ACH'
      return ''
    },

    transactionOtherData () {
      return {
        amount: {
          label: 'Amount',
          text: this.isFunds ? this.$formattedTwoDecimal(this.form.amount) : this.$assets_balance(this.transaction.totalAssetAmount)
        },
        fee: {
          label: 'Fee',
          text: this.$toFixed(this.fee)
        },
        total: {
          label: 'Total',
          text: this.transaction.totalAmount
        }
      }
    },

    transactionInitData () {
      if (!this.transaction) return null

      return [
        {
          label: 'Status',
          text: this.transaction.status
        },
        {
          label: 'Currency',
          text: this.isFunds ? this.transaction.currencyName : this.getCurrencyName(this.form.assetsCurrency, true)
        }
      ]
    },

    transactionData () {
      if (!this.transaction) return null

      const data = []

      if (this.form.depositType === 'Funds') {
        if (this.form.deposit_type === 'international_wires') {
          data.push(
            {
              label: 'Bank Name',
              text: this.form.bank_name
            },
            {
              label: 'Account Name',
              text: this.form.account_number
            },
            {
              label: 'Account Number',
              text: this.form.account_number
            },
            {
              label: 'Account Type',
              text: this.form.account_type
            },
            {
              label: 'Bank Swift Code',
              text: this.form.swift
            },
            {
              label: 'Intermediary Bank Name',
              text: this.form.intermediary_bank_name
            },
            {
              label: 'Intermediary Bank Reference',
              text: this.form.intermediary_bank_reference
            },
            {
              label: 'Intermediary Bank Routing Number',
              text: this.form.intermediary_bank_routing_number
            },
            {
              label: 'Intermediary Bank SWIFT Code',
              text: this.form.intermediary_bank_swift_code
            },
            {
              subtitle: 'INTERMEDIARY BANK ADDRESS',
              spacer: true
            },
            ...this.getAddressData(this.form.intermediary_address),
            {
              subtitle: 'BENEFICIARY ADDRESS',
              spacer: true
            },
            {
              label: 'IBAN',
              text: this.form.iban
            },
            ...this.getAddressData(this.form),
            {
              subtitle: 'ADDITIONAL INFORMATION',
              spacer: true
            },
            ...this.furtherCreditData
          )
        } else if (this.form.deposit_type === 'checks') {
          data.push(
            {
              label: 'Payee',
              text: this.form.payee
            },
            {
              subtitle: 'PAYEE ADDRESS',
              spacer: true
            },
            ...this.getAddressData(this.form)
          )
        } else if (this.form.deposit_type === 'ach') {
          data.push(
            {
              label: 'Name of Bank Account',
              text: this.form.bank_name
            },
            {
              label: 'Account Type',
              text: this.form.account_type
            },
            {
              label: 'Routing Number',
              text: this.form.routing_number
            },
            {
              label: 'Bank Account Number',
              text: this.form.account_number
            },
            {
              label: 'Bank Account Type',
              text: this.form.bank_account_type
            }
          )
        } else if (this.form.deposit_type === 'domestic_wire') {
          data.push(
            {
              label: 'Account Name',
              text: this.transaction.accountName
            },
            {
              label: 'Routing Number',
              text: this.transaction.routingNumber
            },
            {
              label: 'Account Number',
              text: this.transaction.accountNumber
            },
            {
              label: 'Account Type',
              text: this.transaction.accountType
            },
            {
              subtitle: 'BENEFICIARY ADDRESS',
              spacer: true
            },
            ...this.getAddressData(this.form),
            {
              subtitle: 'ADDITIONAL INFORMATION',
              spacer: true
            },
            ...this.furtherCreditData
          )
        } else {
          data.push(
            {
              label: 'Account Number',
              text: `****${this.form.a2a_account_number.substr(4, 3)}`
            }
          )
        }
      } else {
        data.push(
          {
            label: 'Account Number',
            text: `****${this.transaction.toAccountId ? this.transaction.toAccountId.substr(4, 3) : ''}`
          },
          {
            subtitle: this.transaction.outgoingWalletAddress
          }
        )
      }

      data.push({
        label: 'Reference',
        text: this.form.reference
      }, {
        label: 'Memo',
        text: this.form.memo
      })

      if (this.form.depositType === 'Assets') {
        data.push({
          label: 'Note',
          text: `Send only ${this.getCurrencyName(this.form.assetsCurrency, true)} to this wallet address. Any other asset type sent may be lost and unrecoverable.`
        })
      }
      return data
    },

    withdrawalLimit () {
      if (this.form.currency === 'USD') {
        return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.systemAvailableBalance) : null
      } else {
        if (this.selectedAccount) {
          if (this.selectedAccount.fundsCurrencies) {
            const fundCurrency = this.selectedAccount?.fundsCurrencies?.find(item => item.currency.currencyNickname === this.form.currency)
            if (fundCurrency) {
              return parseFloat(fundCurrency.systemAvailableBalance)
            }
          }
        }
      }
      return false
    },

    assetLimit () {
      return this.selectedAccount?.profileSettings ? parseFloat(this.selectedAccount?.profileSettings.assets_limit) : null
    },

    withdrawalAssetLimit () {
      return this.getSystemAssetAvailableBalance()
    }
  },

  watch: {
    'form.deposit_type' () {
      this.getFee(!this.isFunds)
      this.invalidToProvider = false
    },

    'form.account': {
      handler (account) {
        if (account) {
          this.form.deposit_type = null
          this.invalidToProvider = false
          this.getFee(!this.isFunds)
          this.getLinkedAccounts()
          return
        }

        this.fee = null
      }
    },

    'form.amount' () {
      const amount = this.form.amount || 0
      const feePercent = this.feePercent || 0
      this.fee = !amount ? this.legacyFee : parseFloat(this.legacyFee) + parseFloat(amount) * feePercent
    },

    async 'form.a2a_account_number' () {
      const accounts = this.linkedAccounts
      const toAccount = accounts?.find(item => item.accountNumber === this.form.a2a_account_number)
      if (!toAccount) {
        this.fee = 0
      }
    },

    'form.save_as_template' () {
      if (this.form.save_as_template) {
        // focus input
      }
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Withdrawal',
      back: this.previousPageUrl
    })

    this.init()
  },

  methods: {
    getCurrencyName (currencyId) {
      if (this.form.assetsCurrency) {
        let currencies = this.$store.state.app.currencies
        currencies = currencies.filter(currency => {
          return currency.id === currencyId
        })
        return currencies[0] ? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
      }
      return null
    },

    getSystemAssetAvailableBalance () {
      if (this.form.assetsCurrency) {
        const wallets = this.selectedAccount.assetsWallets.filter(wallet => {
          return wallet.currencyId === this.form.assetsCurrency
        })
        return wallets[0] ? wallets[0].systemAssetAvailableBalance : null
      }
    },

    finish () {
      this.addConfirmModal = false
      this.$router.push({ name: 'Dashboard' })
    },

    getAddressData (form) {
      return [
        {
          label: 'Country',
          text: form.country
        },
        {
          label: 'Province/State',
          text: form.state
        },
        {
          label: 'Address',
          text: form.address
        },
        {
          label: 'City',
          text: form.city
        },
        {
          label: 'Postal',
          text: form.postal
        }
      ]
    },

    goBack () {
      this.$router.push(this.previousPageUrl)
    },

    submit () {
      if (this.form.depositType === 'Funds') this.withdraw()
      else this.withdrawAssets()
    },

    alertForHasError () {
      this.$notify.error('There is an error in data you are trying to submit. Please recheck all the fields.')
    },

    async withdraw () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      if (this.withdrawalLimit && this.withdrawalLimit < parseFloat(this.total)) {
        this.invalidBalance = true
        this.alertForHasError()
        return
      }

      if (this.invalidAccountNumber) {
        this.alertForHasError()
        return
      }

      if (this.invalidFundsCurrency) {
        this.alertForHasError()
        return
      }

      this.submitError = false

      if (this.form.deposit_type === 'acc_to_acc') {
        const accounts = this.accountOptions
        const fromProvider = (accounts?.find(item => item.id === this.form.account))?.provider
        const toProvider = (accounts?.find(item => item.accountNumber === this.form.a2a_account_number))?.provider
        if (toProvider) {
          if (fromProvider !== toProvider) {
            this.invalidToProvider = true
            return
          } else {
            this.invalidToProvider = false
          }
        }
      }

      this.loading.submitting = true
      let payload
      if (this.form.deposit_type === 'international_wires') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          accountName: this.form.account_name,
          bankName: this.form.bank_name,
          accountType: this.form.account_type,
          swift: this.form.swift,
          accountNumber: this.form.account_number,
          useIntermediaryBank: this.form.use_intermediary_bank,
          intermediaryBankName: this.form.intermediary_bank_name,
          intermediaryBankReference: this.form.intermediary_bank_reference,
          intermediaryBankIdentifier: this.form.intermediaryBankIdentifier,
          intermediaryBankRoutingNumber: this.form.intermediary_bank_routing_number,
          intermediaryBankSwiftCode: this.form.intermediary_bank_swift_code,
          intermediaryAddress: this.form.intermediary_address,
          bank_country: this.form.bank_country,
          bank_address: this.form.bank_address,
          bank_city: this.form.bank_city,
          bank_state: this.form.bank_state,
          bank_postal: this.form.bank_postal,
          iban: this.form.iban,
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference === '' ? null : this.form.reference,
          memo: this.form.memo,
          useFurtherCreditAccount: this.form.use_further_credit_account,
          futherCreditAccName: this.form.futher_credit_acc_name,
          futherCreditAccNumber: this.form.futher_credit_acc_number,
          completedBy: this.user.firstName + ' ' + this.user.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      } else if (this.form.deposit_type === 'checks') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          payee: this.form.payee,
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          city: this.form.city,
          postal: this.form.postal,
          reference: this.form.reference === '' ? null : this.form.reference,
          memo: this.form.memo,
          completedBy: this.user.firstName + ' ' + this.user.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      } else if (this.form.deposit_type === 'ach') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          routingNumber: this.form.routing_number,
          bankName: this.form.bank_name,
          accountName: this.form.account_name,
          accountType: this.form.account_type,
          bankAccountType: 'checking',
          accountNumber: this.form.account_number,
          reference: this.form.reference === '' ? null : this.form.reference,
          memo: this.form.memo,
          completedBy: this.user.firstName + ' ' + this.user.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      } else if (this.form.deposit_type === 'domestic_wire') {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          accountName: this.form.account_name,
          accountType: this.form.account_type,
          routingNumber: this.form.routing_number,
          accountNumber: this.form.account_number,
          bankName: this.form.bank_name,
          bankAccountType: 'checking',
          country: this.form.country,
          state: this.form.state,
          address: this.form.address,
          address2: this.form.address2,
          city: this.form.city,
          postal: this.form.postal,
          bank_country: this.form.bank_country,
          bank_address: this.form.bank_address,
          bank_city: this.form.bank_city,
          bank_state: this.form.bank_state,
          bank_postal: this.form.bank_postal,
          reference: this.form.reference === '' ? null : this.form.reference,
          useFurtherCreditAccount: this.form.use_further_credit_account,
          futherCreditAccName: this.form.futher_credit_acc_name,
          futherCreditAccNumber: this.form.futher_credit_acc_number,
          memo: this.form.memo,
          completedBy: this.user.firstName + ' ' + this.user.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      } else {
        payload = {
          profileId: this.form.account,
          createdAt: 'CURRENT_TIMESTAMP',
          amount: this.form.amount,
          accountName: this.form.account_name,
          totalAmount: this.total,
          transactionFee: this.fee,
          transactionType: this.form.deposit_type,
          toAccountId: this.form.a2a_account_number,
          reference: this.form.reference === '' ? null : this.form.reference,
          memo: this.form.memo,
          completedBy: this.user.firstName + ' ' + this.user.lastName,
          saveAsTemplate: this.form.save_as_template,
          templateName: this.form.template_name,
          currencyName: this.form.currency
        }
      }

      const response = await this.$store.dispatch('transactions/action', {
        id: 'new',
        action: 'Withdraw',
        payload
      })
      if (response.action.error) {
        this.invalidAccountNumber = true
      } else {
        this.transaction = response.item
        this.$notify.success('Transaction successfully created.')
        this.addConfirmModal = true
        this.invalidAccountNumber = false
        this.invalidFundsCurrency = false
      }
      this.loading.submitting = false
    },

    async withdrawAssets () {
      this.$v.$touch()
      if (this.$v.form.$invalid) {
        this.submitError = true
        return
      }

      this.submitError = false
      this.loading.submitting = true
      const payload = {
        profileId: this.form.account,
        createdAt: 'CURRENT_TIMESTAMP',
        amount: this.form.assetsAmount,
        totalAmount: this.totalAssets,
        transactionFee: this.assetsFee,
        transactionType: 'Asset',
        memo: this.form.memo,
        completedBy: this.user.firstName + ' ' + this.user.lastName,
        currencyId: this.form.assetsCurrency,
        saveAsTemplate: this.form.save_as_template,
        templateName: this.form.template_name,
        sendingTo: this.form.sendingTo,
        reference: this.form.reference
      }
      if (this.form.sendingTo === 'WalletAddress') {
        payload.label = this.form.payeeToName
        payload.outgoingWalletAddress = this.form.payeeToWalletAddress
        payload.tag = this.form.tag
        this.transaction = (await this.$store.dispatch('transactions/action', {
          id: 'new',
          action: 'WithdrawAssets',
          payload
        })).item

        this.$notify.success('Transaction successfully created.')
        this.loading.submitting = false
        this.addConfirmModal = true
      } else {
        payload.toAccountId = this.form.a2a_account_number
        const response = await this.$store.dispatch('transactions/action', {
          id: 'new',
          action: 'WithdrawAccountTransferAssets',
          payload
        })

        if (response.action.errorWallet) {
          this.errorWallet = true
        } else {
          this.transaction = response.item
          this.$notify.success('Transaction successfully created.')
          this.addConfirmModal = true
          this.errorWallet = false
        }
        this.loading.submitting = false
      }
    },

    reset () {
      // reset form
    },

    async init () {
      this.loading.general = true
      await Promise.all([
        this.getTemplateId(),
        this.getRegistrations()
      ])
      this.loading.general = false
    },

    async getRegistrations () {
      const payload = {
        params: { corporate: 1 },
        skip: ['user', 'contact', 'data', 'fees', 'terms', 'serviceProviders', 'complianceScan', 'confirmation', 'documentsChecklist', 'documents']
      }
      this.toRegistrations = await this.$store.dispatch('registration/load', payload)
      return Promise.resolve()
    },

    async getTemplateId () {
      const { action } = await this.$store.dispatch('transactions/action', {
        id: 'new',
        action: 'GetTemplateID'
      })
      this.form.template_name = `Template - ${action.id}`
      return Promise.resolve()
    },

    async getLinkedAccounts () {
      this.linkedAccounts = (await this.$store.dispatch('linkedAccount/action', {
        id: 'new',
        action: 'GetLinkedAccounts',
        payload: { accountId: this.form.account }
      })).action.item
      return Promise.resolve()
    },

    async getFee (isAssets = false) {
      if (!this.form.account) return

      this.loading.account = true
      const payload = {
        profileId: this.form.account,
        transactionType: isAssets ? 'Assets' : this.form.deposit_type,
        entryType: 'Withdrawal'
      }

      const { action } = await this.$store.dispatch('transactions/action', {
        id: 'new',
        action: 'GetFee',
        payload
      })

      if (isAssets) {
        this.assetsFee = action?.fee || 0
      } else {
        this.legacyFee = action.fee
        this.fee = action.fee
        this.feePercent = action.feePercent
      }

      this.loading.account = false
      return Promise.resolve()
    }
  },

  validations () {
    let rules = {}

    if (this.form.depositType === 'Funds') {
      rules = {
        form: {
          amount: { required, decimal },
          account: { required },
          currency: { required },
          deposit_type: { required }
        }
      }

      if (this.limit) {
        rules.form.amount.limit = maxValue(this.limit)
      }

      rules.form.amount.minLimit = minValue(this.fee)
      rules.form.reference = { minLength: minLength(3), maxLength: maxLength(60) }

      if (this.showA2AAccountNumber) {
        rules.form.a2a_account_number = { required }
      }

      if (this.showPayee) {
        rules.form.payee = { required, alphaNum }
      }
      if (this.showAddressForm || this.showInternationalWireForm) {
        rules.form.country = { required }
        rules.form.city = { required }
        if (this.form.country === 'US' || this.form.country === 'CA') {
          rules.form.state = { required }
        }
        rules.form.postal = { required, alphaNum }
        rules.form.address = { required, maxLength: maxLength(35) }
        rules.form.address2 = { maxLength: maxLength(35) }
      }

      if (this.form.use_further_credit_account) {
        rules.form.futher_credit_acc_name = { required }
        rules.form.futher_credit_acc_number = { required }
      }

      if (this.showACHForm) {
        rules.form.account_name = { required }
        rules.form.account_type = { required }
        rules.form.routing_number = { required, minLength: minLength(9), maxLength: maxLength(9) }
        rules.form.account_number = { required, minLength: minLength(4), maxLength: maxLength(17), numeric }
      }

      if (this.showInternationalWireForm) {
        rules.form.account_name = { required }
        rules.form.account_type = { required }
        rules.form.intermediaryBankIdentifier = { required }
        rules.form.account_number = { required, minLength: minLength(4), maxLength: maxLength(34), alphaNumNoSpace }
        rules.form.swift = { required, swift }

        if (this.form.use_intermediary_bank) {
          if (this.form.intermediaryBankIdentifier === 'routing') {
            rules.form.intermediary_bank_routing_number = { required, minLength: minLength(9), maxLength: maxLength(9) }
          } else {
            rules.form.intermediary_bank_swift_code = { required, swift }
          }
          // rules.form.intermediary_address = {
          //   country: { required },
          //   city: { required },
          //   state: { required },
          //   postal: { required, alphaNum },
          //   address: { required }
          // }
        }
      }

      if (this.form.save_as_template) {
        rules.form.template_name = { required }
      }
    } else {
      rules = {
        form: {
          account: { required },
          assetsAmount: { required, assetsAmount },
          assetsCurrency: { required }
        }
      }

      if (this.assetLimit) {
        rules.form.assetsAmount.limit = maxValue(this.assetLimit)
      }

      if (this.form.sendingTo === 'AccountTransfer') {
        rules.form.a2a_account_number = { required }
      } else {
        rules.form.payeeToWalletAddress = { required }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
  .sum {
    margin: 25px 0;
    font-size: 16px;
    div {
      margin: 5px 0;
    }
  }

  .subtitle {
    display: block;
    font-weight: 600;
    margin: 15px 0 10px 0;
    font-size: 16px;
  }

  .buttons {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    .el-button {
      flex: 1;
    }
  }

  .link-text-button {
    color: $link !important;
  }
</style>
