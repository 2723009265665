<template>
  <div>
    <ms-select
      v-model="form.country"
      :options="countryOptions"
      :validator="v.form.country"
      label="Country"
      placeholder="Select Country" />

    <ms-select
      v-if="stateOptions"
      v-model="form.state"
      :options="stateOptions"
      :validator="v.form.state"
      label="Province/State"
      placeholder="Select Province/State" />

    <ms-input
      v-else
      v-model="form.state"
      label="State"
      :validator="v.form.state" />

    <ms-input
      v-model="form.address"
      :label="stateOptions ? 'Address' : 'Address 1'"
      :validator="v.form.address" />

    <ms-input
      v-if="!stateOptions"
      v-model="form.address2"
      label="Address 2"
      :validator="v.form.address2" />

    <ms-input
      v-model="form.city"
      label="City"
      :validator="v.form.city" />

    <ms-input
      v-model="form.postal"
      label="Postal/Zip"
      :validator="v.form.postal" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    usOnly: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    },

    countryOptions () {
      if (this.usOnly) {
        return [
          {
            id: 'US',
            text: 'United States'
          }
        ]
      }

      return window.countries
    },

    stateOptions () {
      if (!['CA', 'US'].includes(this.form.country)) return null

      return window.states[this.form.country]
    }
  }
}
</script>
