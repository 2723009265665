<template>
  <div>
    <div class="hr" />
    <h3 class="subtitle">BANK & BENEFICIARY INFORMATION</h3>

    <bank-validator
      v-model="form.swift"
      :v="v.form.swift"
      label="Bank SWIFT Code"
      type="swift"
      action="BankSwift"
      @confirm="updateSwiftBank($event)"
    />

    <ms-select
      v-model="form.account_type"
      :options="accountOptions"
      :validator="v.form.account_type"
      label="Account Type"
      placeholder="Select Account Type" />

    <ms-input
      v-model="form.account_name"
      label="Account Name"
      :validator="v.form.account_name" />

    <ms-input
      v-model="form.account_number"
      label="Account Number / IBAN"
      :validator="v.form.account_number" />

    <div class="hr" />
    <h3 class="subtitle">INTERMEDIARY BANK INFORMATION</h3>

    <ms-checkbox
      v-model="form.use_intermediary_bank"
      label="Use Intermediary Bank"
      disabled />

    <template v-if="form.use_intermediary_bank">
      <ms-select
        v-model="form.intermediaryBankIdentifier"
        :options="intermediaryBankIdentifierOptions"
        :v="v.form.intermediaryBankIdentifier"
        label="Intermediary Bank Identifier"
        placeholder="Select Intermediary Bank Identifier" />

      <bank-validator
        v-if="form.intermediaryBankIdentifier === 'routing'"
        v-model="form.intermediary_bank_routing_number"
        :v="v.form.intermediary_bank_routing_number"
        label="Routing Number"
        type="bank_routing_number"
        action="Bank"
        @confirm="updateRoutingBank($event)"
      />

      <bank-validator
        v-if="form.intermediaryBankIdentifier === 'swift'"
        v-model="form.intermediary_bank_swift_code"
        :v="v.form.intermediary_bank_swift_code"
        label="SWIFT Code"
        type="swift"
        action="BankSwift"
        @confirm="updateIntSwiftBank($event)"
      />
    </template>

    <div class="hr" />
    <h3 class="subtitle">BENEFICIARY ADDRESS</h3>

    <address-form v-model="form" :v="v" :us-only="false" />
  </div>
</template>

<script>
import AddressForm from './AddressForm.vue'
import BankValidator from './BankValidator'

export default {
  components: {
    AddressForm,
    BankValidator
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    hideBankAccountType: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      accountOptions: window.accountOptions,
      intermediaryBankIdentifierOptions: window.intermediaryBankIdentifierOptions
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  created () {
    this.form.use_intermediary_bank = true
  },

  methods: {
    updateSwiftBank (data) {
      this.form.bank_name = data.name
      this.form.bank_country = data.country
      this.form.bank_state = data.state
      this.form.bank_address = data.line1
      this.form.bank_city = data.city
      this.form.bank_postal = data.postalCode
    },

    updateIntSwiftBank (data) {
      this.form.intermediary_address.country = data.country
      this.form.intermediary_address.state = data.state
      this.form.intermediary_address.address = data.line1
      this.form.intermediary_address.city = data.city
      this.form.intermediary_address.postal = data.postalCode
      this.form.intermediary_bank_name = data.name
    },

    updateRoutingBank (data) {
      this.form.intermediary_address.country = data.country
      this.form.intermediary_address.state = data.region
      this.form.intermediary_address.address = data['street-1']
      this.form.intermediary_address.city = data.city
      this.form.intermediary_address.postal = data['postal-code']
      this.form.intermediary_bank_name = data.name
    }
  }
}
</script>

<style lang="scss" scoped>
  .subtitle {
    display: block;
    font-weight: 600;
    margin: 25px 0 10px 0;
    font-size: 16px;
  }

  .align-bottom {
    padding-top: 30px;
  }

  .buttons {
    width: 100%;
    display: flex;
    .el-button {
      flex: 1;
    }
  }
</style>
