<template>
  <div>
    <!-- <ms-checkbox
      v-model="form.use_further_credit_account"
      label="Use Further Credit Account" /> -->

    <template v-if="form.use_further_credit_account">
      <ms-input
        v-model="form.futher_credit_acc_name"
        label="Further Credit Account Name"
        :validator="v.form.account_name" />

      <ms-input
        v-model="form.futher_credit_acc_number"
        label="Further Credit Account Number"
        :validator="v.form.account_number" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
