<template>
  <div>
    <div class="hr" />
    <h3 class="subtitle">BANK & BENEFICIARY INFORMATION</h3>

    <bank-validator
      v-model="form.routing_number"
      :v="v.form.routing_number"
      label="Bank Routing Number"
      type="bank_routing_number"
      action="Bank"
      @confirm="updateBank($event)"
    />

    <ms-select
      v-model="form.account_type"
      :options="accountOptions"
      :validator="v.form.account_type"
      label="Account Type"
      placeholder="Select Account Type" />

    <ms-input
      v-model="form.account_name"
      label="Account Name"
      :validator="v.form.account_name" />

    <ms-input
      v-model="form.account_number"
      label="Account Number"
      :validator="v.form.account_number" />

    <ms-select
      v-if="!hideBankAccountType"
      v-model="form.bank_account_type"
      :options="bankTypeOptions"
      :validator="v.form.bank_account_type"
      label="Bank Account Type"
      placeholder="Select Bank Account Type" />
  </div>
</template>

<script>
import BankValidator from './BankValidator'

export default {
  components: {
    BankValidator
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    v: {
      type: Object,
      required: true
    },

    hideBankAccountType: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      validating: false,
      accountOptions: window.accountOptions,
      bankTypeOptions: window.bankTypeOptions
    }
  },

  computed: {
    form: {
      get () {
        return this.value
      },

      set (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    updateBank (data) {
      this.form.bank_name = data.name
      this.form.bank_country = data.country
      this.form.bank_address = data['street-1']
      this.form.bank_city = data.city
      this.form.bank_state = data.region
      this.form.bank_postal = data['postal-code']
    }
  }
}
</script>

<style lang="scss" scoped>
  .subtitle {
    display: block;
    font-weight: 600;
    margin: 15px 0 10px 0;
    font-size: 16px;
  }

  .align-bottom {
    padding-top: 30px;
  }

  .buttons {
    width: 100%;
    display: flex;
    .el-button {
      flex: 1;
    }
  }
</style>
